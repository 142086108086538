<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.761" x2="0.5" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_bijbaan" transform="translate(-3327 -1776)">
    <g data-name="icon_bijbaan" id="icon_bijbaan-2" transform="translate(657 -579)">
     <rect :fill="background" data-name="Rectangle 186" height="183" id="Rectangle_186" transform="translate(2670 2355)" width="183"/>
    </g>
    <path d="M43.615,70.5V65.423H8.128L8.077,85.73A10.119,10.119,0,0,0,18.231,95.884H89.307A10.119,10.119,0,0,0,99.461,85.73V65.423H63.923V70.5ZM94.384,24.808H74.026V14.654L63.872,4.5H43.564L33.41,14.654V24.808H13.154A10.184,10.184,0,0,0,3,34.961V50.192A10.119,10.119,0,0,0,13.154,60.346H43.615V50.192H63.923V60.346H94.384a10.184,10.184,0,0,0,10.154-10.154V34.961A10.184,10.184,0,0,0,94.384,24.808Zm-30.461,0H43.615V14.654H63.923Z" data-name="Icon material-business-center" fill="url(#linear-gradient)" id="Icon_material-business-center" transform="translate(3364.731 1817.308)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>